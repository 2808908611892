import React, {useRef, useState, useEffect} from 'react'
import { after } from 'underscore';
// import DateDisplay from '../components/DateDisplay'
import LandingHeader from '../components/LandingHeader'
import RowInfoRight from '../components/RowInfoRight'
import RowInfoLeft from '../components/RowInfoLeft'
import MoreInfo from '../components/MoreInfo'
import CustomNav from '../components/Nav'
import Loader from '../components/loader'
import CustomNavAlt from '../components/NavAlt';
import CarouselHeader from '../components/CarouselHeader';
import OurServices from '../components/OurServices';
import SustainableDrive from '../components/SustainableDrive';
import OurPartners from '../components/OurPartners';

const HomePageAlt: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    const [loading, setLoading] = useState(false);

    const interestDailyRefTag  = useRef<null | HTMLDivElement>(null)
    const winBigRefTag  = useRef<null | HTMLDivElement>(null)
    const cashoutRefTag  = useRef<null | HTMLDivElement>(null)
    const noMinBalRefTag  = useRef<null | HTMLDivElement>(null)

    const infoParams = [
        {title: "Earn Interest Daily", text: "Your Solis account goes to work immediately after your deposit, allowing you to earn interest on your deposits daily! You will see your interest grow each day.", tag_: 'interest_daily'},
        {title: "Win Big Each Week!", text: "Earn points every time you save with Solis. The more you save, the more points you earn, increasing your chances of winning cash back!!",  tag_: 'win_big'},
        {title: "Cashout on your terms.", text: "Make easy withdrawals from your Solis account straight to your Mobile Money wallets.", tag_: 'cashout'},
        {title: "No minimum balance, no account fees.", text: "No hidden agenda, just savings that give you real benefits!", tag_: 'no_min_bal'},
    ]

    useEffect(()=>{
        scrollToView("")
    })

    const scrollToView = (type_: string) => {
        if(type_==infoParams[0].tag_){
            interestDailyRefTag.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        } else if(type_==infoParams[1].tag_){
            winBigRefTag.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        } else if(type_==infoParams[2].tag_){
            cashoutRefTag.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        } else if(type_==infoParams[3].tag_){
            noMinBalRefTag.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        } else {
            topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
        
    }

    const loadComplete_ = after(10,()=>{
        console.log("Loaded ..")
        setLoading(false);
    }) 

    return (<>
        <div ref={topPageRef}></div>
            <div style={{display: loading ? "block" : "none", position: 'relative', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Loader loading={loading} />
            </div>
            <div style={{ display: loading ? "none" : "flex", position: 'relative', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <CustomNavAlt scrollToView_={scrollToView}/>
                <CarouselHeader loadComplete={loadComplete_} />
                <OurServices />
                <SustainableDrive />
                <OurPartners />
                {/* <div ref={interestDailyRefTag}></div>
                <RowInfoRight title={infoParams[0].title} text={infoParams[0].text} image="/images/Hero-Phone.svg" />
                <div ref={winBigRefTag}></div>
                <RowInfoLeft title={infoParams[1].title} text={infoParams[1].text} image="/images/Hero-Phone-2.svg" />
                <div ref={noMinBalRefTag}></div>
                <RowInfoRight title={infoParams[3].title} text={infoParams[3].text} image="/images/Hero-Phone-3.svg" />
                <div ref={cashoutRefTag}></div>
                <RowInfoLeft title={infoParams[2].title} text={infoParams[2].text} image="/images/ideas-woman.svg" /> */}
                {/* <MoreInfo /> */}
                {/*<DateDisplay />*/}
            </div>
    </>
    )
}

export default HomePageAlt
