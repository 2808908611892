import React, { useEffect, useRef } from "react";
import CustomNavAlt from "../components/NavAlt";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom"
import { ROUTES } from '../resources/routes-constants'

const PurposePage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)

    useEffect(()=>{
        scrollToView()

    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return <section>
        <div ref={topPageRef}></div>
            <div style={{ display: "flex", position: 'relative', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <CustomNavAlt scrollToView_={scrollToView}/>
            </div>
            <Row>
                <Col className="purpose-heading-title-col" >
                    <h1>For Your Business</h1>
                </Col>
            </Row>
            <Row>
                <div className="purpose-header">
                    <div style={{backgroundImage: `url('/altImages/imgg/a0ywg1k2.png')`, backgroundSize: 'cover', filter: 'brightness(40%)', width: '100%', height: '100%'}}></div>
                    <div className="pupose-header-title">
                        <h1>A partner you can trust.</h1>
                        <h3>Financing when and where you need it, ensuring your business runs smoothly.</h3>
                    </div>
                </div>
            </Row>
            <Container className="purpose-desc-div">
                <Row className="my-auto">
                    <Col xs={12} sm={12} md={6} className="my-auto">
                        <Row>
                            <Col className="purpose-desc-header">
                                <h1>Retailers</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="purpose-desc-content">
                                <p>
                                We work with our partners to grow their businesses and manage it effectively.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="purpose-desc-content">
                                <Link to={ROUTES.RETAILERS_ROUTE} className="purpose-desc-link">Learn more</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="purpose-image-col">
                        <div className="purpose-image">
                            <img src="/altImages/imgg/retailstoreitems22.png" />
                        </div>
                    </Col>
                </Row>

                <Row className="my-auto mt-4">
                    <Col xs={{order: 'last'}} sm={{order: 'first'}} md={6} className="purpose-image-col">
                        <div className="purpose-image">
                            <img src="/altImages/imgg/manonmotorbike2unedited.png" />
                        </div>
                    </Col>
                    <Col xs={{order: 'first'}} sm={{order: 'last'}} md={6} className="my-auto">
                        <Row>
                            <Col className="purpose-desc-header content-text-align-right">
                                <h1>Asset Financing</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="purpose-desc-content content-text-align-right">
                                <p>
                                We have partners to help you grow your income.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="purpose-desc-content content-text-align-right">
                                <Link to={ROUTES.ASSET_FINANCING_ROUTE} className="purpose-desc-link">Learn more</Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
    </section>
}

export default PurposePage