import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlackButton from './BlackButton'
import CustomNav from './Nav'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import LogoCircle from './LogoCircle'
import Loader from './loader'

type Props = {
    showText: boolean
}

const WaitList: React.FC<Props> = ({showText}) => {
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [loading, setLoading] = useState(false);
    const [detailsSubmitted, setDetailsSubmitted] = useState(false)
    const [showError, setShowError] = useState(false)
    const [error_, setError_] = useState('')

    const formRef = useRef(null)

    const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
    const SHEET_ID = process.env.REACT_APP_SHEET_ID;
    const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
    const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;
    const SCRIPTURL = process.env.REACT_APP_SCRIPT

    const submitHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault()

        setLoading(true)

        const params = {Name: firstName, Email: email, MobileNumber: mobileNumber}
        
        // axios.post('https://sheet.best/api/sheets/3a7996a0-b3a1-44b5-b87d-9b0c3a88b1fe', params)
        //     .then(response => {
        //         console.log("Response is ")
        //         console.log(response);
        //     })
        const regExp = /[a-zA-Z]/g;

        if(email=="" && mobileNumber==""){
            setError_("Please enter an email address or a mobile number")
            setLoading(false)
            setShowError(true)
        } else if (regExp.test(mobileNumber)) {
            setError_("Please enter a valid mobile number")
            setLoading(false)
            setShowError(true)
        } else {
            fetch(SCRIPTURL!, {method: 'POST', body: new FormData(formRef.current!)})
            .then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setFirstName('')
                setEmail('')
                setMobileNumber('')
                setLoading(false)
                setDetailsSubmitted(true)
                setShowError(false)
                setError_('')
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setShowError(false)
                setError_('')
            })
        }

    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(e.target.value)

		console.log(e.target.getAttribute('name'))

		switch(e.target.getAttribute('name')){
			case 'firstname':
				setFirstName(e.target.value)
				break;
			case 'email':
				setEmail(e.target.value)
				break;
            case "mobilenumber":
                setMobileNumber(e.target.value)
                break;
			default:
				return null;
		}

        email != '' ? setShowError(false) : mobileNumber != '' ? setShowError(false) : setShowError(true)
    }

    return (<Form onSubmit={submitHandler} ref={formRef}>
                <Card className="loginCard">
                    {/* <Card.Body style={{ paddingTop: '40px', paddingLeft: '54px', paddingRight: '54px'}}>
                        <Card.Title><h1 className="loginCardTitle">Sign in to Solis</h1></Card.Title>
                        <Card.Text className="mt-4 loginCardTextGrey">
                            To sign in, please type in the email address or mobile number linked to your Solis account and your Solis password.
                        </Card.Text>
                        <Card.Text className="mt-4 loginCardText">
                            Email Address or Mobile Number
                        </Card.Text>
                        <Card.Text className="mt-4">
                            <Row>
                                <input type="text" className="loginNameTextBox" />
                            </Row>
                        </Card.Text>
                        <button className="loginButton">Continue</button>
                    </Card.Body> */}
                    <Card.Body className="loginCardBody">
                        <Card.Title><h1 className="loginCardTitle">Join our waitlist!</h1></Card.Title>
                        {
                            showText ? 
                            <>
                            <Card.Text className="mt-4 loginCardText">
                                We are building the future of savings. A product that rewards you for saving and encourages you to get closer to your goals. <br/>
                            </Card.Text>
                            <Card.Text className="mt-4 loginCardText">
                                Get early access to see what we are building as a beta-tester.
                            </Card.Text>
                            </> : ''
                        }
                        {
                            showError ? <Card.Text className="mt-4 errorText">
                                {error_}
                            </Card.Text> : ''
                        }
                        {
                            loading ? <div className="loader-card"><Loader loading={loading} /></div> :
                            detailsSubmitted ? 
                            <Card.Text className="my-4 loginCardText loader-card">
                            Thank you for joining our waitlist!
                            </Card.Text>
                            :
                            <Card.Text className="mt-4">
                                <Row className="mt-4 loginCardText">
                                    <Col xs={12} sm={12} md={12}>First Name</Col>
                                    <Col xs={12} sm={12} md={12}><input type="text" value={firstName} name="firstname" style={{width: '100%'}} onChange={(e: React.ChangeEvent<HTMLInputElement>)=>inputChange(e)} required /></Col>
                                </Row>
                                <Row className="mt-4 loginCardText">
                                    <Col xs={12} sm={5} md={5}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>Mobile Number</Col>
                                            <Col xs={12} sm={12} md={12}><input type="text" value={mobileNumber} name="mobilenumber" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>inputChange(e)} style={{width: '100%'}} /></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                    <Col xs={12} sm={5} md={5}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>Email</Col>
                                            <Col xs={12} sm={12} md={12}><input type="email" value={email} name="email" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>inputChange(e)} style={{width: '100%'}}/></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Text>
                        }
                        {detailsSubmitted ? '' : loading ? '' : <button className="loginButton mt-4" type="submit">Join</button>}
                    </Card.Body>
                </Card>
            </Form>
    )
}

export default WaitList