import React, { useEffect, useRef } from 'react'
import CustomNav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNavAlt from '../components/NavAlt';
import { Carousel } from 'react-bootstrap';

const AboutPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        scrollToView()
    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <Row>
                <Col className="purpose-heading-title-col">
                    <h1>Our Company</h1>
                </Col>
            </Row>
            <section className="carousel-header-3">
                <div className='backdrop-img' style={{backgroundImage: `url('/altImages/team/Abouts1.jpg')`, backgroundPosition: 'top', backgroundSize: 'cover'}}></div>
                <Carousel className="carousel-div centerDiv">
                    <Carousel.Item>
                        <div className='carousel-div-img' style={{backgroundImage: `url('/altImages/team/Abouts1.jpg')`, backgroundPosition: 'top', backgroundSize: 'cover'}}></div>
                        {/* <img src="/altImages/team/Abouts1.jpg" width="100%" height="100%" /> */}
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='carousel-div-img' style={{backgroundImage: `url('/altImages/team/Abouts2.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
                        {/* <img src="/altImages/team/Abouts1.jpg" width="100%" height="100%" /> */}
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='carousel-div-img' style={{backgroundImage: `url('/altImages/team/Abouts5.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
                        {/* <img src="/altImages/team/Abouts1.jpg" width="100%" height="100%" /> */}
                    </Carousel.Item>
                </Carousel>
            </section>
            <Container>
                <Row style={{textAlign: 'left'}}>
                    <Col xs={12} md={12} className='mt-4'>
                        <h1 className="moreInfoHeader">Our Purpose</h1>
                        <p className="infoTexts">
                            Our goal is to provide financial services digitally to customers. 
                        </p>
                        <p className='infoTexts'>
                        We exist to make banking a pleasurable experience by creating products that customers really want and to deliver our customers the best service. 
                        </p>
                        <p className='infoTexts'>
                        We also believe that banking should be affordable and transparent and therefore we charge no or low fees (only when necessary). 
                        </p>
                        <p className='infoTexts'>
                        As your financial institution, we are committed to helping you achieve your financial goals and to grow with you.
                        </p>
                        <p className="infoTexts">
                            We are focused on provided banking solutions (credit, savings and collections) to the FMCG retail and mobility sectors. Individuals also have access to savings through our app.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} className='mt-4'>
                        <h1 className="moreInfoHeader">Our Licensing</h1>
                        <p className="infoTexts">
                        We are fully licensed by the Bank of Ghana as a Tier 2 Microfinance Institution, and have our clients&apos; deposits insured by GDPC.
                        </p>
                        <p className="infoTexts">
                        As your financial institution, we are committed to helping you achieve your financial goals and to grow with you.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutPage