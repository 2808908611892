import React, { useEffect, useRef } from 'react'
import CustomNav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNavAlt from '../components/NavAlt';
import { Carousel } from 'react-bootstrap';

const ContactUsPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        scrollToView()
    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <Row>
                <Col className="purpose-heading-title-col">
                    <h1>Contact Us</h1>
                </Col>
            </Row>
            <Container>
                <Row style={{textAlign: 'left'}}>
                    <Col xs={12} md={7} className='mt-4'>
                        <h1 className="moreInfoHeader">Locate Us</h1>
                        <p className="infoTexts">
                        1st Floor, Unique Floral Building, 33 Blohum Street, Dzorwulu, Accra, Ghana
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={7} className='mt-4'>
                        <h1 className="moreInfoHeader">Call Us</h1>
                        <p className="infoTexts">
                        +233 599 577 963
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUsPage