import React from 'react'
import DateDisplay from '../components/DateDisplay'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import LogoCircle from './LogoCircle'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom"

type Props = {
    scrollToView_: (type_: string)=>void
}

const CustomNav: React.FC<Props> = ({scrollToView_}) => {
    return (
        <Navbar collapseOnSelect expand="lg" className="customNavBar">
            <Container fluid className="customNavBarContainer">
                <Navbar.Brand href="#home"><Link to="/" className="homeLink"><img src="/images/SolisLogo-011.png" className="logoImg" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <NavDropdown className="mx-3 mb-1" title="Benefits" id="navbarScrollingDropdown">
                        <NavDropdown.Item href="#action3" onClick={()=>scrollToView_('interest_daily')}>Earn Interest Daily</NavDropdown.Item>
                        <NavDropdown.Item href="#action3" onClick={()=>scrollToView_('win_big')}>Win Big Each Week</NavDropdown.Item>
                        <NavDropdown.Item href="#action3" onClick={()=>scrollToView_('cashout')}>Cashout on your terms</NavDropdown.Item>
                        <NavDropdown.Item href="#action3" onClick={()=>scrollToView_('no_min_bal')}>No minimum balance, no account fees</NavDropdown.Item>
                    </NavDropdown>
                    <Nav className="me-auto">
                        <Link to="/about-us" className="navLink mx-3">About us</Link>
                    </Nav>
                    <Nav className='mb-1'>
                        {/* <Link to="/login" className="navLink mx-3 my-auto">Log In</Link> */}
                        <Link to="/get_solis" className="navLinkButton text-center mx-1">
                            Get Solis
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default CustomNav
