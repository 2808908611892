import React, { useEffect, useRef } from 'react'
import DateDisplay from '../components/DateDisplay'
import CustomNav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoCircle from '../components/LogoCircle'
import CustomNavAlt from '../components/NavAlt';

const PrivacyPolicyPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        scrollToView()

    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <LogoCircle position="fluid" size="large" />
            <Container>
                <Row className="justify-content-center mt-4">
                    <Col xs={12} md={7}>
                        <h1 className="moreInfoHeader">Privacy Policy</h1>
                        <p className="termsAndConditionsSecondHeader">Welcome to Solis!</p>
                        {/* <p className="infoTexts"><b>Table of Contents</b></p>
                        <p>
                            <div className="tableOfContentsDiv">
                                <ul style={{listStyleType:'none', color:'#F06A66'}}>
                                    <li className="mt-1">Collection and Control of Personal Information</li>
                                    <li className="mt-1">
                                        <ul style={{listStyleType:'none'}}>
                                            <li className="mt-1">Information You Provide to Us</li>
                                            <li className="mt-1">Information about Your Transactions with Us</li>
                                            <li className="mt-1">Information from Other Sources</li>
                                            <li className="mt-1">Social Sharing Features</li>
                                            <li className="mt-1">Information We Collect Automatically from our Services</li>
                                        </ul>
                                    </li>
                                    <li className="mt-1">Protection of Personal Information</li>
                                    <li className="mt-1">Disclosure of Personal Information</li>
                                    <li className="mt-1">Updates and Changes to Privacy statement</li>
                                </ul>
                            </div>
                        </p> */}
                        <p><a href="www.solisfinance.com">www.solisfinance.com</a> is a website operated by Solis.</p>
                        <p className="infoTexts">In using the website and our Apps, you are declaring that you have read this Privacy Policy and understand and agree to our Terms of Use.</p>

                        <p className="infoTexts">We care about the privacy and security of your personal information. We have taken all reasonable measures to ensure that you can visit our site reliably and securely. The information you transmit on this website remains private, and we will not disclose any of this information to third parties unless we have obtained permission from you.</p>

                        <p className="infoTexts">We do not actively collect and process any information.</p>

                        <p className="infoTexts">As with most sites, this website gathers certain information automatically. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, the files viewed on our website (e.g., HTML pages and graphics), operating system, date/time stamp, and/or clickstream data to analyse trends to aggregate and administer the website. We also utilise Google Analytics to better understand how individual users experience our website.</p>

                        <p className="infoTexts">You can control or block the use of cookies at the individual browser level. However, disabling or deleting cookies may limit your use of certain features or functions on this website.</p>

                        <p className="infoTexts">Under data protection law, you have rights including:
                            <ul>
                                <li>Your right of access – You have the right to ask us for copies of your personal information.</li>
                                <li>Your right to rectification – You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
                                <li>Your right to erasure – You have the right to ask us to erase your personal information in certain circumstances.</li>
                                <li>Your right to restriction of processing – You have the right to ask us to restrict the processing of your information in certain circumstances.</li>
                                <li>Your right to object to processing – You have the right to object to the processing of your personal data in certain circumstances.</li>
                                <li>Your right to data portability – You have the right to ask that we transfer the information you gave us to another organisation, or to you, in certain circumstances.</li>
                            </ul>
                        </p>

                        <p className="infoTexts">You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>

                        <p className="infoTexts">
                            <h5>What personal information does Solis collect and use?</h5>
                        </p>
                        <p className="infoTexts">
                            <ul>
                                <li>Personal information we collect may include but not limited to; name, sex, date of birth, addresses, email address, mobile phone, occupation and information contained in supporting documents such as proof of identity and proof of address.</li>
                                <li>We will require your image and a valid national ID to be able to verify you with the National Identification Authority (NIA).</li>
                                <li>Solis may collect, store, process the following types of Personal Information: Biometric Data, contact list to enable easy access to beneficiaries, financial information.</li>
                            </ul>
                        </p>

                        <p className="infoTexts">This privacy policy only relates to this website and our Apps. If you use a link on this website to take you to another website not managed by us, your privacy will not be protected in accordance with this policy. You will need to check the respective websites’ privacy statement. We make no representations as to the accuracy or any other aspect of information contained in other websites.</p>

                        <p className="infoTexts">Please contact us at <a href='mailto: support@solisfinance.com'>support@solisfinance.com</a> if you wish to make a request or have any questions about this website’s Privacy Policy.</p>


<h5>Cookies</h5>

<p className="infoTexts">A cookie is a small piece of data, that is sent to your computer or mobile phone browser from a website’s computer and is stored on your device’s hard drive. Many websites do this whenever a user visits their website to track traffic flows and page visits. For further information, visit <a href="#">All About Cookies | Online Privacy and Digital Security</a></p>

<p className="infoTexts">We use these cookies to gather information about how visitors use our site so that we can improve the service. These cookies provide us details on the number of visitors to the site and specific pages visited within the site.</p>

<p className="infoTexts">Google Analytics cookies do not collect personal data about you. For further information, please visit: <a href="www.google.com">Privacy Policy - Privacy & Terms - Google</a> </p>

<p className="infoTexts">
    There are a number of different types of cookies, however, our website uses:
    <ul>
        <li>Functionality – Our Company uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
        <li>Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company may some time share some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
    </ul>
</p>

<p className="infoTexts">How to manage cookies</p>

<p className="infoTexts">Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</p>

<p className="infoTexts">How to manage cookies</p>

<p className="infoTexts">You can block the download of cookies by altering the privacy settings on your web browser, however, please note that if you do this you may not be able to use the full functionality of this website. If you want to remove previously stored cookies, you can manually delete the cookies at any time. For comprehensive information on how to do this on a wide variety of browsers, please visit:</p>

<p><a href="#">Home</a></p>

<p><a href="#">Opt Out - NAI: Network Advertisement Initiative</a></p>

<p className="infoTexts">To opt out of being tracked by Google Analytics across all websites, please visit;</p>

<p><a href="#">Google Analytics Opt-out Browser Add-on Download Page</a></p>

<p className="infoTexts">Disclaimer</p>

<p className="infoTexts">The information contained in this website is for general information purposes only. The information is provided by Solis and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

<p className="infoTexts">In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>

<p className="infoTexts">Through this website you are able to link to other websites which are not under the control of Solis. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>

<p className="infoTexts">Every effort is made to keep the website up and running smoothly. However, Solis takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
<br/><br/>
<h3>Terms of use</h3>
<p className="infoTexts">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</p>
<br/>
<p className="infoTexts"><b>WHAT’S IN THESE TERMS?</b></p>

<p className="infoTexts">These terms tell you the rules for using our <a href="www.solisfinance.com">www.solisfinance.com</a> (our site). WHO WE ARE AND HOW TO CONTACT US</p>

<p className="infoTexts"><b>BY USING OUR SITE YOU ACCEPT THESE TERMS</b></p>

<p className="infoTexts"><a href="www.solisfinance.com">www.solisfinance.com</a> is a site operated by Solis (“We”). We are registered in Ghana under company number CS062422018 and have our registered office at Dzorwulu. Our main trading address is Solis Microfinance, Dzorwulu.</p>

<p className="infoTexts">To contact us, please email <a href="malito: support@solisfinance.com">support@solisfinance.com</a></p>

<p className="infoTexts"><b>BY USING OUR SITE YOU ACCEPT THESE TERMS</b></p>

<p className="infoTexts">By using our site, you confirm that you accept these terms of use and that you agree to comply with them.</p>

<p className="infoTexts">If you do not agree to these terms, you must not use our site.
We recommend that you print a copy of these terms for future reference. THERE ARE OTHER TERMS THAT MAY APPLY TO YOU. These terms of use refer to the following additional terms, which also apply to your use of our site:</p>

<p className="infoTexts">Our Privacy Policy which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.</p>

<p className="infoTexts"><b>WE MAY MAKE CHANGES TO THESE TERMS</b></p>

<p className="infoTexts">We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated on 18 November 2022.</p>

<p className="infoTexts"><b>WE MAY MAKE CHANGES TO OUR SITE</b></p>

<p className="infoTexts">We may update and change our site from time to time.</p>

<p className="infoTexts"><b>WE MAY SUSPEND OR WITHDRAW OUR SITE</b></p>

<p className="infoTexts">Our site is made available free of charge.</p>

<p className="infoTexts">We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>

<p className="infoTexts">You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>

<p className="infoTexts"><b>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</b></p>

<p className="infoTexts">If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>

<p className="infoTexts">We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>

<p className="infoTexts">If you know or suspect that anyone other than you know your user identification code or password, you must promptly notify us at <a href='malito: support@solisfinance.com'>support@solisfinance.com.</a></p>

<p className="infoTexts"><b>HOW YOU MAY USE MATERIAL ON OUR SITE</b></p>

<p className="infoTexts">Unless otherwise specified, we are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>

<p className="infoTexts">You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>

<p className="infoTexts">You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>

<p className="infoTexts">Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>

<p className="infoTexts">You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>

<p className="infoTexts">If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>

<p className="infoTexts"><b>DO NOT RELY ON INFORMATION ON THIS SITE</b></p>

<p className="infoTexts">The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>

<p className="infoTexts">Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>

<p className="infoTexts"><b>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</b></p>

<p className="infoTexts">Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>

<p className="infoTexts">We have no control over the contents of those sites or resources.</p>

<p className="infoTexts"><b>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</b></p>

<p className="infoTexts">We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</p>

<p className="infoTexts">We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>

<p className="infoTexts">use of, or inability to use, our site; or
use of or reliance on any content displayed on our site. In particular, we will not be liable for:</p>
<ul>
    <li>loss of profits, sales, business, or revenue;</li>

    <li>business interruption;</li>

    <li>loss of anticipated savings;</li>

    <li>loss of business opportunity, goodwill or reputation; or</li>

    <li>any indirect or consequential loss or damage.</li>
</ul>
<p className="infoTexts"><b>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</b></p>

<p className="infoTexts">We do not guarantee that our site will be secure or free from bugs or viruses.</p>

<p className="infoTexts">You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>

<p className="infoTexts">You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Cyber & Information Security Directive. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>

<p className="infoTexts"><b>RULES ABOUT LINKING TO OUR SITE</b></p>

<p className="infoTexts">You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>

<p className="infoTexts">You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>

<p className="infoTexts">You must not establish a link to our site in any website that is not owned by you.</p>

<p className="infoTexts">Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>

<p className="infoTexts">We reserve the right to withdraw linking permission without notice.</p>

<p className="infoTexts">If you wish to link to or make any use of content on our site other than that set out above, please contact <a href='malito: support@solisfinance.com'>support@solisfinance.com</a>.</p>

<p className="infoTexts"><b>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</b></p>

<p className="infoTexts">These terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by Ghana. We both agree to the exclusive jurisdiction of the courts of Ghana.</p>

{/* <p className="infoTexts">If you feel that we are not abiding by this Policy, you should contact us immediately via email at</p>

<p className="infoTexts"><span style={{color:'#F06A66'}}>support@solis.com.gh</span></p> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PrivacyPolicyPage