import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const OurPartners: React.FC = () => {
    return <section className="our-partners">
        <Container>
            <Row>
                <Col className="section-title sustainable-drive-title">
                    <h1 className="services-head-title">Our Partners</h1>
                </Col>
            </Row>
            <Row>
                <Col className="mt-2">
                    <img src="/altImages/expresspay.png" height={250} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/crossSwitch.jpeg" height={250} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/NIA.jpeg" height={250} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/gtBank.jpeg" height={250} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/vodafoneCash.png" height={150} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/mtnMomo.jpeg" height={150} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/airtelTigoMoney.png" height={250} />
                </Col>
                <Col className="mt-2">
                    <img src="/altImages/enterpriseInsurance.png" height={150} />
                </Col>
            </Row>
        </Container>
    </section>
}

export default OurPartners