import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const SustainableDrive: React.FC = () => {
    return <section className="sustainable-drive">
        <Container>
            <Row>
                <Col className="section-title sustainable-drive-title">
                    <h1 className="services-head-title">Our Sustainability Drive</h1>
                </Col>
            </Row>
            <Row>
                <Col className="sustainable-drive-description">
                    <p>Focus on Sustainability helps to maintain a balance between our environment, the economy and equity. It improves the quality of our lives, protects our ecosystem and preserves natural resources for future generations.</p>
                    <p>We are committed to Sustainability.</p>
                </Col>
            </Row>
            <Row className="sustainability-steps">
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-1 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>1</h1></span>
                                    <span><h3>NO <br/>POVERTY</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG1.png" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-2 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>5</h1></span>
                                    <span><h3>GENDER <br/>EQUALITY</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG5.jpg" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-3 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>7</h1></span>
                                    <span><h3>AFFORDABLE AND CLEAN ENERGY</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG7.png" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-5 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>8</h1></span>
                                    <span><h3>DECENT WORK AND ECONOMIC GROWTH</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG8.png" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-4 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>9</h1></span>
                                    <span><h3>INDUSTRY, INNOVATION AND INFRASTRUCTURE</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG9.png" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-6 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>10</h1></span>
                                    <span><h3>REDUCED INEQUALITIES</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG10.png" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-7 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <span><h1>11</h1></span>
                                    <span><h3>SUSTAINABLE CITIES AND COMMUNITIES</h3></span>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG11.jpg" width="100%" />
                </Col>
                <Col className="s-step-col mt-4" xs={6} sm={6} md={3}>
                    {/* <div className="s-step s-step-8 mt-4">
                        <Container>
                            <Row>
                                <Col className="s-box-heading">
                                    <h1>13</h1>
                                    <h3>CLIMATE ACTION</h3>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                    <img src="/altImages/imgg/SDG/SDG13.png" width="100%" />
                </Col>
            </Row>
        </Container>
    </section>
}

export default SustainableDrive