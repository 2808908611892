import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SmallBlackButton from './SmallBlackButton'
import CustomNav from './Nav'
import Card from 'react-bootstrap/Card';
import LogoCircle from './LogoCircle'

const GetSolisLandingHeader: React.FC = () => {
    return (
        <div className="getSolisLandingDiv">
            <Container fluid="md" className="getSolisContainer">
                <Row>
                    <Col xs={12} sm={6}>
                        <div className="getSolisInfoBox">
                            <h1 className="getSolisHeader">Download Solis, open an account!</h1>
                            <div className="row mt-1">
                                <span className="getSolisInfoText my-2">Either enter your email or download our app from the play store or app store.</span>
                            </div>
                            <div className="row my-1">
                                <div className="col-12">
                                {/* <a href="https://testflight.apple.com/join/hSZPPY3X" className="getItLogo"><img src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" className="getOnApple" /></a> */}
                                <a href="#" className="getItLogo"><img src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" className="getOnApple" /></a>
                                    &nbsp;&nbsp;
                                {/* <a href="https://play.google.com/store/apps/details?id=com.solis.finance.solis" className="getItLogo"><img src="/images/google-play-badge.png" className="getOnGoogle"/></a> */}
                                <a href="#" className="getItLogo"><img src="/images/google-play-badge.png" className="getOnGoogle"/></a>
                                </div>
                            </div>
                        </div>        
                    </Col>
                    <Col xs={12} sm={6}>
                        <Row className="justify-content-end">
                            <img src="/images/GroupOpen.png" className="getSolisImage" />
                            <img src="/images/Frame1_1.png" className="getSolisIpad" />
                            <img src="/images/Group_5.png" className="getSolisIphone" />
                        </Row>
                    </Col>
                </Row>
                <LogoCircle position="up-top" size="large"/>
            </Container>
        </div>
    )
}

export default GetSolisLandingHeader