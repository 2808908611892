import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
// import { FaHeart } from "react-icons/fa";
import { TbBrandFacebook } from "react-icons/tb";
import { TbBrandTwitter } from "react-icons/tb";
import { TbBrandInstagram } from "react-icons/tb";
import SmallBlackButton from './SmallBlackButton'
import { Link } from "react-router-dom"
import { ROUTES } from '../resources/routes-constants';



const Footer: React.FC = () => {
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#FFEDED' }}>
            <Container fluid="md" className="footerContainer">
                {/* <Row className="justify-content-center mt-4">
                    <h1 className="footerHeader mt-3">Convenient. Simple. Secure.</h1>
                    <Col md={6} className="text-center footerSummaryText mt-3">
                        <span>Your security is important to us.</span> <br/>Solis has invested in world-leading cybersecurity to protect your information.
                    </Col>    
                </Row> */}
                <Row className="justify-content-center footerLinks">
                    <Col xs={12} md="auto" className="mt-3">
                        <Stack gap={1}>
                            <div className="footerHeader mb-4" style={{textAlign: 'left'}}><Link to="/" className="homeLink"><img src="/images/SolisLogo-01_1.svg" className="logoFooter" /></Link> </div>
                            {/* <div className="mt-2"><a href="#" className="getItLogo"><img src="/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" className="getOnAppleSmall" /></a>&nbsp;&nbsp;<a href="#" className="getItLogo"><img src="/images/google-play-badge.png" className="getOnGoogleSmall"/></a></div> */}
                            <div className="mt-4"><small>Follow us on </small>&nbsp;&nbsp;&nbsp;<a href="https://web.facebook.com/profile.php?id=100089588594558" target="_blank" rel="noreferrer"><TbBrandFacebook size={24} style={{color: '#0C6BFD'}} /></a> &nbsp;&nbsp;&nbsp;<a href="https://twitter.com/SolisFinance" target="_blank" rel="noreferrer"><TbBrandTwitter size={24} style={{color: '#0CE0FD'}} /></a> &nbsp;&nbsp;&nbsp;<a href="#" target="_blank" rel="noreferrer"><TbBrandInstagram size={24} style={{color: '#FD0CB4'}} /></a></div>
                        </Stack>
                    </Col>
                    <Col xs={12} md="auto" className="mt-3">
                        <Stack gap={1}>
                            <div className="footerSmallHeader"><Link to={ ROUTES.HOMEPAGE_ROUTE } className="footerLink">Home</Link></div>
                            <div className="footerSmallText"><Link to={ ROUTES.HOMEPAGE_ROUTE } className="footerLink">For You</Link></div>
                            <div className="footerSmallText"><Link to={ ROUTES.PURPOSE_ROUTE } className="footerLink">For Your Business</Link></div>
                            <div className="footerSmallText"><Link to={ ROUTES.OUR_COMPANY_ROUTE } className="footerLink">Our Company</Link></div>
                        </Stack>
                    </Col>
                    <Col xs={12} md="auto" className="mt-3">
                        <Stack gap={1}>
                            <div className="footerSmallHeader">Contact Us </div>
                            <div className="footerSmallText"><a href="mailto: support@solisfinance.com" className="footerLink">support@solisfinance.com</a></div>
                            <div className="footerSmallText"><a href="tel: +233599577963" className="footerLink"><b>Tel: </b>+233599577963</a></div>
                            <div className="footerSmallText"><b>Address: </b>33 Blohum Street, Dzorwulu, Accra Ghana</div>
                        </Stack>
                    </Col>
                    {/* <Col xs={12} md="auto" className="mt-3">
                        <Stack gap={0}>
                            <div className="footerSmallHeader">Resources</div>
                            <div className="footerSmallText"><Link to='/FAQs' className="footerLink">FAQs</Link></div>
                        </Stack>
                    </Col> */}
                    <Col xs={12} md="auto" className="mt-3">
                        <Stack gap={1}>
                            <div className="footerSmallHeader">Legal</div>
                            <div className="footerSmallText"><Link to='/terms_and_conditions' className="footerLink">Terms And Conditions</Link></div>
                            <div className="footerSmallText"><Link to='/privacy_policy' className="footerLink">Privacy Policies</Link></div>
                            {/* <div className="footerSmallText"><Link to='/documents/LicenseToOperate.jpg' className="footerLink" target="_blank">License to Operate</Link></div> */}
                            {/* <div className="footerSmallText"><Link to='/documents/RGDChangeofNametoSolis.pdf' className="footerLink" target="_blank">Certificate of Incorporation</Link></div> */}
                            <div className="footerSmallText"><Link to='/FAQs' className="footerLink">FAQs</Link></div>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer