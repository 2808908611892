import React, { useEffect, useRef } from "react";
import CustomNavAlt from "../components/NavAlt";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom"

const AssetFinancing: React.FC = () => {

    const topPageRef = useRef<null | HTMLDivElement>(null)

    useEffect(()=>{
        scrollToView()

    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return <section>
        <div ref={topPageRef}></div>
            <div style={{ display: "flex", position: 'relative', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <CustomNavAlt scrollToView_={scrollToView}/>
            </div>
            <Row>
                <Col className="purpose-heading-title-col" >
                    <h1>Asset Financing</h1>
                </Col>
            </Row>
            <Row>
                <div className="purpose-alt-header">
                    <div style={{backgroundImage: `url('/altImages/imgg/deliveryRider.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', filter: 'brightness(40%)', width: '100%', height: '100%'}}></div>
                    <div className="pupose-header-title">
                        <h1>Would you like to increase your income?</h1>
                        <h2>Let us help you.</h2>
                    </div>
                </div>
            </Row>
            <Container className="purpose-desc-div">
                <Row>
                    <Col className="section-title purpose-subs-title">
                        <h1 className="purpose-subs-head-title">What we offer</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4}>
                        <Row>
                            <Col className="tab-image">
                                <div className="mx-auto">
                                    <img src="/altImages/imgg/SDG/rider1.png" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto tab-header">
                                Asset Financing
                            </Col>
                        </Row>
                        <Row>
                            <Col className="tab-content">
                             Affordable loans to help you scale your productivity and contribute to a greener environment.
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col xs={12} sm={12} md={4}>
                        <Row>
                            <Col className="tab-image">
                                <div className="mx-auto">
                                    <img src="/altImages/imgg/SDG/coins.png" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto tab-header">
                                Collections
                            </Col>
                        </Row>
                        <Row>
                            <Col className="tab-content">
                                Solutions to help you collect your sales digitally and have instant access to your revenue
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={12} md={4}>
                        <Row>
                            <Col className="tab-image">
                                <div className="mx-auto">
                                    <img src="/altImages/imgg/SDG/chart.png" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto tab-header">
                                Data
                            </Col>
                        </Row>
                        <Row>
                            <Col className="tab-content">
                                Analytics that makes tracking and reporting easy and reliable for business owners
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="send-us-a-message-btn">
                    <Col><a href="tel: +233599577963" className="footerLink"><Button variant="danger">Send us a message we&lsquo;ll take it from there.</Button></a></Col>
                </Row>
                <Row className="terms-and-conditions">
                    <Col>*Terms and conditions apply</Col>
                </Row>
                <Row>
                    <Col style={{width: '100%'}}>
                        <img src="/altImages/imgg/equip.jpeg" width="100%" />
                    </Col>
                </Row>
            </Container>
    </section>
}

export default AssetFinancing