import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const RowInfoLeft: React.FC = () => {
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Container fluid="md">
                <Row className="justify-content-center">
                    <Col xs={12} sm={3} md={3} className="joinUsBox my-1">
                        <Row>
                            <Col>
                            <div className="joinInfoCounter justify-content-center text-center"><small className="centerDiv">1</small></div>
                            </Col>
                        </Row>
                        <Row style={{ position: 'relative', top: '30px', paddingRight: '8px', paddingLeft: '8px', width: '100%' }}>
                            <Col className="joinUsText">Download Solis on Google Play or the App Store.</Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="joinUsBox my-1">
                        <Row>
                            <Col>
                            <div className="joinInfoCounter justify-content-center text-center"><small className="centerDiv">2</small></div>
                            </Col>
                        </Row>
                        <Row style={{ position: 'relative', top: '30px', paddingRight: '8px', paddingLeft: '8px', width: '100%' }}>
                            <Col className="joinUsText">Open the app and follow the prompts on your screen to open an account.</Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="joinUsBox my-1">
                        <Row>
                            <Col>
                            <div className="joinInfoCounter justify-content-center text-center"><small className="centerDiv">3</small></div>
                            </Col>
                        </Row>
                        <Row style={{ position: 'relative', top: '30px', paddingRight: '8px', paddingLeft: '8px', width: '100%' }}>
                            <Col className="joinUsText">Add money to your account and start your new life of freedom from fees.</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default RowInfoLeft