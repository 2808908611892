import React, { useState, useEffect, useRef } from 'react'
import DateDisplay from '../components/DateDisplay'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNav from '../components/Nav'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'
import Collapse from 'react-bootstrap/Collapse';
import CustomNavAlt from '../components/NavAlt';

const FAQsPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    const [getAccount, setGetAccount] = useState(false);
    const [haveAccount, setHaveAccount] = useState(false);
    const [minBalance, setMinBalance] = useState(false);
    const [monthlyFees, setMonthlyFees] = useState(false);
    const [limits, setLimits] = useState(false);
    const [statementAvail, setStatementAvail] = useState(false);
    const [statementLoc, setStatementLoc] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(false);
    const [withdrawFunds, setWithdrawFunds] = useState(false);
    const [secured, setSecured] = useState(false);
    const [fundsLoc, setFundsLoc] = useState(false);
    const [insured, setInsured] = useState(false);
    const [isLicensed, setIsLicensed] = useState(false);
    const [contact, setContact] = useState(false);
    
    useEffect(()=>{
        scrollToView()

    },[])

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}


    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <Container>
                <Row className="mt-4">
                    <div className="FAQHeading">
                        <div className="centerDiv text-center">
                            <span className="FAQHeadingTitle">Frequently Asked Questions</span><br/>
                            <span className="FAQHeadingDesc">Your guide to using Solis.</span>
                        </div>
                    </div>
                </Row>
                <Row className="justify-content-center" style={{ position: 'relative', paddingTop: '156px' }}>
                    <Col xs={11} md={8}>
                        <span className="FAQTableTitle">ACCOUNT / GETTING STARTED</span>
                        <div className="tableOfContentsDiv FAQText">
                            <Table striped>
                                <tbody style={{ paddingLeft: '18px' }}>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setGetAccount(!getAccount)}>How do I get a Solis Account?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={getAccount}>
                                            <div id="example-collapse-text">
                                                <p>To sign up for a Solis account, you will need to provide your name, phone number and your Ghana Card details.</p>
                                                <p>
                                                    Due to regulatory reasons for opening a savings account, we also collect data including your address, date of birth and Socila Security number. This information is securely transmitted and protected using modern security technology.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setHaveAccount(!haveAccount)}>Do I need to have a bank account to use Solis?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={haveAccount}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    No. Solis is a digital savings wallet on its own.
                                                </p>
                                                <p>
                                                    To deposit or withdraw on the Solis App, you will have to link your mobile money account with your Solis Account.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setMinBalance(!minBalance)}>Is there a minimum balance?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={minBalance}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    No. There is no minimum balance required to have a Solis account.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setMonthlyFees(!monthlyFees)}>Do I pay monthly fees or charges for my account?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={monthlyFees}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    There are no monthly or other fees for using your Solis account.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setLimits(!limits)}>What are the limits on how much cash I can deposit?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={limits}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    There are no limits to how much you can deposit into your Solis account. You can deposit through your linked Mobile Money account, or deposit cash into your Solis account at your nearest ABSA branch.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setStatementAvail(!statementAvail)}>Are statements available?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={statementAvail}>
                                            <div id="example-collapse-text">
                                                <p>Yes. On the 15th of every month, statements for the previous month are made available.</p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setStatementLoc(!statementLoc)}>Where do I find my statements?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={statementLoc}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    You can find your statements in the app by the following these steps:
                                                    <ol>
                                                        <li>Tap &quot;Settings&quot;.</li>
                                                        <li>Tap &quot;Account&quot;.</li>
                                                        <li>Tap &quot;Statements&quot;.</li>
                                                    </ol>
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setPaymentMethod(!paymentMethod)}>How do I add payment methods?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={paymentMethod}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    To add a payment method, follow these steps:
                                                    <ol>
                                                        <li>Tap the Settings icon at the bottom of the screen.</li>
                                                        <li>Tap &quot;Account&quot;.</li>
                                                        <li>Tap &quot;Payment Method&quot;.</li>
                                                        <li>Enter Mobile Money Details.</li>
                                                        <li>Tap &apos;Done&apos;.</li>
                                                    </ol>
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setWithdrawFunds(!withdrawFunds)}>How do I withdraw funds?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={withdrawFunds}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    To withdraw, follow these steps:
                                                    <ol>
                                                        <li>Tap the Account icon at the bottom of the screen.</li>
                                                        <li>Tap &quot;Withdraw&quot;.</li>
                                                        <li>Enter the Amount</li>
                                                        <li>Select Recipient Mobile Money Number or Capture New Recipient Mobile Money Number.</li>
                                                        <li>Enter PIN to authorize transaction.</li>
                                                        <li>Tap &apos;Done&apos;.</li>
                                                    </ol>
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>

                    <Col xs={11} md={8} style={{ paddingTop: '100px' }}>
                        <span className="FAQTableTitle">SECURITY</span>
                        <div className="tableOfContentsDiv FAQText">
                            <Table striped>
                                <tbody style={{ paddingLeft: '18px' }}>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setSecured(!secured)}>How is my account secured or protected?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={secured}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    You can choose to protect your account with our two-factor authentication process or through biometric authentication. 
                                                </p>
                                                <p>
                                                    To turn this on, follow these steps:
                                                    <ol>
                                                        <li>Tap the Settings icon at the bottom of the screen.</li>
                                                        <li>Tap &quot;Settings&quot;.</li>
                                                        <li>Tap &quot;Privacy and Security&quot;</li>
                                                        <li>To enable two-factor authentication, tap &quot;Two-Factor Auth Method&quot; and select an authentication method (either SMS or Authentication App). To enable biometrics, tap the toggle for &quot;Require Device Auth&quot;.</li>
                                                    </ol>
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setFundsLoc(!fundsLoc)}>Where are my funds held?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={fundsLoc}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    Your funds are held by Solis Finance. Solis Finance is regulated by the Bank of Ghana (BOG). Your funds are GDPC insured.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setInsured(!insured)}>Is my money insured?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={insured}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    Security is our #1 priority.
                                                    <br/>
                                                    All Solis funds are GDPC insured, which means that no matter what happens the cash in your account is guaranteed safe.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setIsLicensed(!isLicensed)}>Is Solis a licensed financial institution?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={isLicensed}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    We are licensed by the Bank of Ghana(BOG)
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>

                    <Col xs={11} md={8} style={{ paddingTop: '100px' }}>
                        <span className="FAQTableTitle">CONTACT</span>
                        <div className="tableOfContentsDiv FAQText">
                            <Table striped>
                                <tbody style={{ paddingLeft: '18px' }}>
                                    <tr>
                                        <td className='faq-btn' onClick={() => setContact(!contact)}>How can I contact Solis?</td>
                                    </tr>
                                    <tr>
                                        <Collapse in={contact}>
                                            <div id="example-collapse-text">
                                                <p>
                                                    <b>For Banking Assistance Inquiries:</b>
                                                    <br/>
                                                    Phone: <a href="tel: +233599577963" className="footerLink">+(233) 599 577 963</a>
                                                    <br/>
                                                    Email: <a href="mailto: info@solisfinance.com" className="footerLink">info@solisfinance.com</a>
                                                </p>
                                                <p>
                                                    <b>All other Inquiries:</b>
                                                    <br/>
                                                    Phone: <a href="tel: +233599577963" className="footerLink">+(233) 599 577 963</a>
                                                    <br/>
                                                    Email: <a href="mailto: support@solisfinance.com" className="footerLink">support@solisfinance.com</a>
                                                </p>
                                            </div>
                                        </Collapse>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FAQsPage