import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoCircle from '../components/LogoCircle'
import { Button, Form, Modal } from 'react-bootstrap';
import CustomNavAlt from '../components/NavAlt';
import Loader from '../components/loader';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

const TargetSavings: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    const [show, setShow] = useState(false);

    const formRef = useRef(null)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [loading, setLoading] = useState(false);
    const [detailsSubmitted, setDetailsSubmitted] = useState(false)
    const [showError, setShowError] = useState(false)
    const [error_, setError_] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    
    useEffect(()=>{
        scrollToView()

    })

    useEffect(()=>{
        setSuccessMessage('')      
    },[])

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  
  

    const submitHandler = async (event: { preventDefault: () => void; }) => {
        const SCRIPTURL = process.env.REACT_APP_TARGET_SAVE_SCRIPT;

        event.preventDefault()

        console.log("Script url is "+SCRIPTURL)

        setLoading(true)

        // const params = {Name: name, Email: email, MobileNumber: mobileNumber}
        
        // axios.post('https://sheet.best/api/sheets/3a7996a0-b3a1-44b5-b87d-9b0c3a88b1fe', params)
        //     .then(response => {
        //         console.log("Response is ")
        //         console.log(response);
        //     })
        const regExp = /[a-zA-Z]/g;

        if(email=="" && mobileNumber==""){
            setError_("Please enter an email address or a mobile number")
            setLoading(false)
            setShowError(true)
        } else if (regExp.test(mobileNumber)) {
            setError_("Please enter a valid mobile number")
            setLoading(false)
            setShowError(true)
        } else {
            console.log("Script url is "+SCRIPTURL);

            const advancedMatching = { em: email, ct: '', db: '', fn: '', ge: '', country: '', ln: '', ph: '', st: '', zp: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
            const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: false, // enable logs
            };
            ReactPixel.init('600033705329080', advancedMatching, options);  

            ReactPixel.trackCustom('TargetSaveWaitList', {name: name});

            fetch('https://script.google.com/macros/s/AKfycbz0b0ywPzn79BRCUuvX4ExNcTywgWK7hcTvHa2cZrQyZSyYzoFDHaM0z7AP6wVva1TV/exec', {method: 'POST', body: new FormData(formRef.current!)})
            .then(res => {
                console.log("SUCCESSFULLY SUBMITTED ")
                console.log(res)
                setSuccessMessage("You have been included in the waitlist. A customer service representative will contact you shortly. Thank you")
                setName('')
                setEmail('')
                setMobileNumber('')
                setLoading(false)
                setDetailsSubmitted(true)
                setShowError(false)
                setError_('')
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setShowError(false)
                setError_('')
            })
        }

    }
    

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <LogoCircle position="fluid" size="large" />
            <section style={{width: '100vw', overflow: 'hidden'}}>
                {/* <Row style={{position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Col sm={12} style={{position: 'relative', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <div onClick={handleShow} className='centerDiv targetSaveAd' style={{ backgroundImage: `url('/images/target_savings.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                            <img width="100%" src='/images/target_savings.jpg'/>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col xs={{ order: 'last', span: 12 }} sm={{ order: 'last', span: 12 }} md={{ order: 'first', span: 6 }} style={{ zIndex: '2', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='my-2'>
                        {
                            successMessage == "" ? <Form onSubmit={submitHandler} ref={formRef}>
                                <h2>Sign up for Target Save</h2>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={name} placeholder="Name" name="name" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setName(e.target.value)} disabled={loading == true ? true : false} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="text" value={mobileNumber} placeholder="Number" name="mobileNumber" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setMobileNumber(e.target.value)} disabled={loading == true ? true : false} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" value={email} placeholder="name@example.com" name="email" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setEmail(e.target.value)} disabled={loading == true ? true : false} />
                                </Form.Group>
                                { loading == true ? <Loader loading={loading} /> : <Button type='submit' className='join-target-waitlist-btn' size='lg'>Sign Up</Button> }
                            </Form> : <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                <h3 style={{color: "#EBF5FB", textShadow: `0px 1px 4px #23430C`}}>{ detailsSubmitted==true ? successMessage : '' }</h3>
                                <Link to={ROUTES.HOMEPAGE_ROUTE} className='btn btn-lg home-btn'>Go Home</Link>
                                </div>
                        }
                    </Col>
                    <Col xs={{ order: 'first', span: 12 }} sm={{ order: 'first', span: 12 }} md={{ order: 'last', span: 6 }} className='my-2' style={{ zIndex: '2', justifyContent: 'center', display: 'flex'}}>
                        <div className='targetSaveAd3'>
                            <img width="100%" src='/images/TargetSavePortrait.jpg'/>
                        </div>
                    </Col>
                </Row>
            </section>
            <div className='modalImgTargetSave' style={{ backgroundImage: `url('/images/TargetSaveLandscape.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                </div>

            <Modal show={show} onHide={handleClose} fullscreen={true}>
                    <Modal.Header closeButton style={{ zIndex: '2'}}>
                    </Modal.Header>
                    <Modal.Body style={{ zIndex: '2'}}>
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='my-2'>
                                    <Form onSubmit={submitHandler} ref={formRef}>
                                        <h3 style={{color: "#0274D2"}}>{ detailsSubmitted==true ? successMessage : '' }</h3>
                                        <h2>Join Our Waitlist</h2>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" value={name} placeholder="Name" name="name" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setName(e.target.value)} disabled={loading == true ? true : false} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control type="text" value={mobileNumber} placeholder="Number" name="mobileNumber" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setMobileNumber(e.target.value)} disabled={loading == true ? true : false} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" value={email} placeholder="name@example.com" name="email" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setEmail(e.target.value)} disabled={loading == true ? true : false} />
                                        </Form.Group>
                                        { loading == true ? <Loader loading={loading} /> : <Button type='submit' className='join-target-waitlist-btn' size='lg'>Join Waitlist</Button> }
                                    </Form>
                                </Col>
                                <Col xs={12} sm={12} md={6} className='my-2'>
                                    <div className='targetSaveAd2' style={{ backgroundImage: `url('/images/target_savings.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                        <img width="100%" src='/images/target_savings.jpg'/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ zIndex: '2'}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                    <div className='modalImgTargetSave' style={{ zIndex: 0, backgroundImage: `url('/images/target_savings.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                    </div>
            </Modal>
        </div>
    )
}

export default TargetSavings