import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const OurServices: React.FC = () => {
    return <section className="our-services">
        <Container>
            <Row>
                <Col className="section-title">
                    <h1 className="services-head-title">Our Services</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className="services-content">
                        <Col className="my-auto" style={{textAlign: 'center'}} xs={12} sm={12} md={4}>
                            <img src="/altImages/imgg/SDG/diamond_light.jpeg" />
                        </Col>
                        <Col xs={12} sm={12} md={8}>
                            <Row><Col className="services-title">Asset Financing</Col></Row>
                            <Row>
                                <Col className="services-description">
                                    <p>We believe offering asset financing that works for our customers, hassle free, allowing them to spread the cost of their asset over a reasonable time period in a stress-free and convenient manner, should be the focus of any financing partner who seeks the financial empowerment of its customers.</p>
                                    <p>We allow customers to purchase assets aimed at improving their livelihoods; and provide flexible repayment options that work.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="services-content">
                        <Col className="my-auto" style={{textAlign: 'center'}} xs={12} sm={12} md={4}>
                            <img src="/altImages/imgg/SDG/chart.png" />
                        </Col>
                        <Col xs={12} sm={12} md={8}>
                            <Row><Col className="services-title">Data</Col></Row>
                            <Row>
                                <Col className="services-description">
                                    <p>With real time data, we can make better informed and timely business decisions.</p>
                                    <p>We know what&apos;s going on at a glance, we can improve operational efficiency, and identify new opportunities through trends that may indicate future growth areas.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="services-content">
                        <Col className="my-auto" style={{textAlign: 'center'}} xs={12} sm={12} md={4}>
                            <img src="/altImages/imgg/SDG/coins.png" />
                        </Col>
                        <Col xs={12} sm={12} md={8}>
                            <Row><Col className="services-title">Collections & Payments</Col></Row>
                            <Row>
                                <Col className="services-description">
                                    <p>Digital revenue collections and access to funds are essential for modern-day businesses to be efficient, nimble, and fiscally stable.</p>
                                    <p>Wholesalers and retailers can benefit from digital revenue collections and access to funds in terms of Improved cash flow, reduced costs and increased security.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
}

export default OurServices