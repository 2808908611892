import React from 'react'

type Props = {
    position: string
    size: string
}

const LogoCircle: React.FC<Props> = ({position, size}) => {
    return (
        <div className={position=="up-top" ? 'logoDesignLarge' : position=="fluid" ? 'logoDesignLarge3' : position=="side" ? 'logoDesignLarge2' : 'logoDesignIcon'}>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
                <div className={size=="large" ? "logoComponentsLarge" : size=="icon" ? "logoComponentsIcon" : ""}>
                </div>
            </div>
    )
}

export default LogoCircle