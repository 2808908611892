import React, { useState } from 'react'
import DateDisplay from '../components/DateDisplay'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import LogoCircle from './LogoCircle'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from "react-router-dom"
import { ROUTES } from '../resources/routes-constants'

type Props = {
    scrollToView_: (type_: string)=>void
}

const CustomNavAlt: React.FC<Props> = ({scrollToView_}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const showDropdown = ()=>{
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    }
    const showDropdown2 = ()=>{
        setShow2(!show2);
    }
    const hideDropdown2 = () => {
        setShow2(false);
    }

    return (
        <Navbar sticky="top" collapseOnSelect expand="lg" className="customNavBarAlt">
            <Container fluid className="customNavBarContainer">
                <Navbar.Brand href="#home"><Link to="/" className="homeLink"><img src="/images/SolisLogo-011.png" className="logoImg" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Link to="/about-us" className="navLink mx-3">About us</Link> */}
                    </Nav>
                    <Nav className='mr-auto'>
                        {/* <Link to="/login" className="navLink mx-3 my-auto">Log In</Link> */}
                        <Link to="/" className="navLink mx-3 my-auto">Home</Link>
                        <Link to="/target-savings" className="navLink mx-3 my-auto">Target Savings</Link>
                        <NavDropdown 
                            onClick={()=>navigate(ROUTES.PURPOSE_ROUTE)} 
                            className="mx-3 alt-nav-dropdown" 
                            title="For Your Business" 
                            id="navbarScrollingDropdown1"
                            show={show}
                            onMouseEnter={showDropdown} 
                            onMouseLeave={hideDropdown}
                            style={{ backgroundColor: '#FDDADA' }}
                            >
                            <NavDropdown.Item href={ROUTES.RETAILERS_ROUTE}>Retailers</NavDropdown.Item>
                            <NavDropdown.Item href={ROUTES.ASSET_FINANCING_ROUTE}>Asset Financing</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown 
                            onClick={()=>navigate( ROUTES.OUR_COMPANY_ROUTE )}
                            className="mx-3 alt-nav-dropdown" 
                            title="Our Company" 
                            id="navbarScrollingDropdown2"
                            show={show2}
                            onMouseEnter={showDropdown2} 
                            onMouseLeave={hideDropdown2}
                            >
                            <NavDropdown.Item href={ROUTES.CONTACT_US_ROUTE}>Contact Us</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Link to="/get_solis" className="navLinkButton text-center mx-1">
                            Get Solis
                        </Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default CustomNavAlt
