import React from "react";
import { Carousel } from "react-bootstrap";

type Props = {
    loadComplete: any
}

const CarouselHeader: React.FC<Props> = ({loadComplete}) => {
    return <section className="carousel-header">
        <img src="/altImages/imgg/main.gif" width="100%" />
        {/* <Carousel interval={8000} className="carousel-div centerDiv">
            <Carousel.Item>
                <img src="/altImages/imgg/headgear1.gif" width="100%" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="/altImages/imgg/man.gif" width="100%" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="/altImages/imgg/tomatoes1.gif" width="100%" />
            </Carousel.Item>
    </Carousel> */}
    </section>
}

export default CarouselHeader