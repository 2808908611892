import React, { useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoCircle from '../components/LogoCircle'
import CustomNavAlt from '../components/NavAlt';
import { Link } from 'react-router-dom';

const TermsAndConditionsPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    const accountClosureRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        const searchParams = new URLSearchParams(window.location.search);

        if(searchParams.has("account-info")){
            if(searchParams.get("account-info")=="TRUE"){
                scrollToAccountInfoView()
            } else {
                scrollToView()
            }
        } else {
            scrollToView()
        }
    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}
    const scrollToAccountInfoView = () => {accountClosureRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <LogoCircle position="fluid" size="large" />
            <Container>
                <Row className="justify-content-center mt-4">
                    <Col xs={12} md={7}>
                        <h1 className="moreInfoHeader">Terms & Conditions for Solis Account</h1>
                        <p className="termsAndConditionsSecondHeader">Welcome to Solis!</p>
<p><h5>Introduction</h5></p>

<p className="infoTexts">Thank you for signing up for a Solis Savings Account. By ticking the dialogue box, you agree to the following terms and conditions which govern your Account.</p>

<p className="infoTexts">Solis Accounts are opened and operated by Solis Microfinance Ltd and this service is currently available to anyone who meets the requirements set by Bank of Ghana. These requirements may be change from time to time in which case you will be notified and asked to accept the changes. Also, deposits made into your Solis Account are protected by the Ghana Deposit Protection Corporation.</p>

<p className="infoTexts"><b>1. Application for Account</b>
        <ol type="a">
            <li>You can apply for an Account through the Solis app if you have a valid Ghana Card ID.</li>
            <li>Persons below the age of 18 (minors) cannot open a Solis Account.</li>
            <li>We require your personal details and certain specific information before a decision is made on your application. You must provide complete and accurate information.</li>
            <li>We will verify your identity and may refuse to open an Account for you if we are not satisfied with proof of your identity.</li>
            <li>Your Account will be held by Solis Microfinance Ltd</li>
        </ol>
</p>


<p className="infoTexts"><b>2. Interest rates, Fees and Charges</b>
    <ol type="a">
        <li>Each savings account shall attract an interest rate. These rates may change over time but will be duly communicated to you. The current rate is 6% on an annual basis.</li>
        <li>There are currently no fees for using the Solis App. Fees may be introduced and revised but will duly be communicated with Solis Customers.</li>
        <li>Transacting / Limits
            <ol type="i">
                <li>We may place transactional limits on your Account.</li>
                <li>{'You may access the available funds in your Account at any time using the Solis Mobile App and or our website. While we will make every reasonable attempt to provide the services in accordance with the terms and conditions governing our App and Website, we provide the Services "as is" and without any warranties.'}</li>
            </ol>
        </li>
        <li>You must authorize your transactions with your secret PIN or biometrics, which you create when you register, or by such other method we may prescribe from time to time.</li>
        <li>Any use of or transactions concluded through the Solis website are subject to the terms and conditions on our website, as may be amended by us from time to time.</li>
        <li>We may verify and confirm any record of a deposit into your Account. Our records will be taken as correct unless the contrary is proven</li>
    </ol>
</p>

<p className="infoTexts"><b>3. Security and Unauthorised Use</b>
        <ol type="a">
            <li>You are required to select a confidential 6-digit PIN during onboarding. The PIN is mandatory for the use of all Solis features in such a manner that no transaction could be effected without entering and validating this PIN or biometric. You have three (3) attempts to enter the right PIN. If you enter the wrong PIN on your third attempt, the Solis Account will be disabled. In the event that the Account is disabled, customers can call our support team (at 0599577963) to reset their PINs.</li>
            <li>You are responsible, for keeping your PIN secret and for all transactions that take place on your Account with your PIN and you indemnify us against any claims made in respect of such transactions. Your PIN shall not be communicated to anyone, must be kept in a very confidential manner and should in no case be written on any document. You must ensure this PIN is always composed out of sight of any individual.</li>    
            <li>If at any time you believe or know that your cell phone or PIN has been stolen or compromised, you can either send us an email with your registered email address on the Solis Account or call us immediately on 0599577963. After a brief verification, we will block the Solis Account as soon as we reasonably can. You will remain responsible for all transactions that occur until your PIN is blocked.</li>
            <li>Should you dispute that any withdrawal debited to your Account was authorised by you, you will have to prove it was not authorised by you.</li>
            <li>You are responsible for protecting your computer against computer viruses when you use the Internet to access our Solis website and Mobile App. We are not liable for any computer program or code that may originate from our systems and you indemnify us against any claims made in this regard.</li>
        </ol>
</p>

<p className="infoTexts"><b>4. Electronic Statements</b>
        <ol type="a">
            <li>You must check monthly e-statements as soon as you receive it and inform us within 30 days of the date of the statement if you think that a statement is not correct. If you do not do this within this timeframe you hereby waive the right to dispute any transactions reflected on the statement or to recover any losses from unauthorised transactions reflected on the statement</li>
        </ol>
</p>

<div ref={accountClosureRef}></div>
<p className="infoTexts"><b>5. Closing Your Account</b>
        <ol type="a">
            <li>We will close your Account upon receiving a closure request from you. Instructions to close an account can be found in this link: <Link to="/account-information">Account Closure</Link></li>
            <li>We can close your Account, restrict activity on your Account or suspend access to your Account if in any way we know or suspect your Account is being used fraudulently, negligently or for illegal activities or if we must do so to comply with the Law.</li>
            <li>We can close your Account if we believe that you are in breach of these terms and conditions, are trying to compromise our systems or are unreasonably interfering with any Services provided by us.</li>
            <li>We may choose at any time to close your Account to protect our business interests.</li>
        </ol>
</p>

 

<p className="infoTexts"><b>6. Failure or Malfunction of Equipment</b>
        <ol type="a">
            <li>We are not responsible for any loss arising from any failure, malfunction, or delay in any cell phone networks, and cell phones, the Internet or terminals or any of its supporting or shared networks, resulting from circumstances beyond our reasonable control</li>
        </ol>
</p>

<p className="infoTexts"><b>7. Notices</b>
        <ol type="a">
            <li>The address you supply on your Solis Account is regarded as your chosen address where notices may be given and documents in legal proceedings may be served. You must update us immediately via the Solis App or call our customer support should your physical, postal or email address change.</li>
            <li>We are entitled to send any notice to an email address specified on your Solis Account. This communication will be regarded as having been received by you, unless the contrary is proved.</li>
            <li>Any correspondence that we send to you by post will be considered to have arrived within seven days of posting and any correspondence that we send to you by fax or email will be considered to have arrived on the day that it was sent.</li>
            <li>We are entitled to send information to you via SMS to the contact cell phone number provided on the Solis App and as amended from time to time.</li>
            <li>You should send any legal notice to us at our chosen address:<br/>
                Solis Microfinance<br/>
                33 Unique Floral Building, GA-121-5296<br/>
                Dzorwulu, Accra.<br/>
            </li>
            <li>You acknowledge and agree that this agreement will be regarded as having been entered into in Accra and any breach of this agreement will be considered as having taken place in Accra.</li>
        </ol>
</p>

<p className="infoTexts"><b>8. Consents and Conduct of the Account</b>
        <ol type="a">
            <li>You, the Account holder, consent to us:
                <ol type="i">
                    <li>Making enquiries about your credit record with any credit reference agency and any other party to confirm the details on this application;</li>
                    <li>Providing regular updates regarding the conduct of the Account to the credit reference agencies and allowing the credit reference agencies to in turn make the record and details available to other credit grantors.</li>
                </ol>
            </li>
        </ol>
</p>

<p className="infoTexts"><b>9. Fraud Prevention</b>
        <ol type="a">
            <li>You, the Account holder, consent to us:
                <ol type="i">
                    <li>Carrying out identity and fraud prevention checks and sharing information relating to this application with the Ghana Police or any fraud prevention or security agency as required by Law;</li>
                    <li>Providing details to the Ghana Police or any fraud prevention or security agency of any conduct on your Account that gives us reasonable cause to suspect that the Account is being used for improper purposes; and</li>
                    <li>You understand and agree that the record of this suspicion will then be available to other members of the Ghana Police or any fraud prevention or security agency should they carry out credit or other checks on your name.</li>
                </ol>
            </li>
        </ol>
</p>

<p className="infoTexts"><b>10. General</b>
        <ol type="a">
            <li>We may at any time amend these terms and conditions by notice to customers. Any amendment will not constitute a novation of this agreement.</li>
            <li>You shall not vary any of these terms and conditions.</li>
            <li>You agree that we may sue you in any court of competent jurisdiction.</li>
            <li>A favour or concession we may give you will not affect any of our rights against you.</li>
            <li>These terms and conditions are governed by Ghanaian Law.</li>
            <li>You must notify us if you are under an administration order, sequestration or any other form of insolvency.</li>
            <li>You must pay all our expenses in recovering any amounts you owe us including legal fees.</li>
            <li>We will not be responsible to you for any indirect, consequential or special damages arising from any act or omission by us or any third party for whom we are responsible and whether arising in contract, statute or tort.</li>
            <li>You are responsible for your connection to the Internet and all costs associated with that same connection.</li>
            <li>You must update your details via the Solis App.</li>
            <li>You agree that your information, including your personal information, your telephone conversations with our call centre and your transactions will be recorded and stored for record keeping purposes for 5 years from date of closure of Account.</li>
            <li>We are obliged by Law to regularly update your personal particulars, such as your current residential address and contact information. We may contact you from time to time in this regard through the Solis App or via your email.</li>
            <li>All copyright, trademarks and other intellectual property rights used as part of our services or contained on our documents are owned by Solis. You agree that you acquire no rights thereto.</li>
            <li>You accept that all transactions effected on your cell phone are subject to other terms and conditions available on our website.</li>
        
        
        </ol>
</p>

<p className="infoTexts"><b>11. Sanctions</b>
        <ol type='a'>
            <li>Any abusive and/or fraudulent usage of a Solis Account and any false declaration may be punished by the Laws in place in Ghana.</li>
            <li>Any abusive and/or fraudulent usage of a Solis Account and any false declaration may also lead us to suspend temporarily or in definitively rights to access to Solis services.</li>
            <li>Any cost engaged by us in recovering transactions and undue payments made by the Account user will be borne by the user.</li>
            <li>Any fee that could not have been debited from the user’s Account due to a fraudulent usage will be recovered with an additional legal interest rate.</li>
            <li>Any transaction or action from you on your Solis Account triggering a malfunction of the system and requiring a technical intervention will be charged to you.</li>
        </ol>
</p>

<p className="infoTexts"><b>12. Customer Responsibilities</b>
        <ol type='a'>
            <li>It is the responsibility of the Account owner to ensure that he/she provides accurate personal information to Solis. To this end, the Account owner undertakes to Solis that information shared is true and correct and that he/she is obligated to provide any additional information that is required from time to time.</li>
            <li>You must not use our App or any other service or platform provided by us to commit any offence(s); Fraud and Money Laundering and any other financial offence that is not accepted under the laws of the country or contravenes the Electronic Payment Act 772 and the Money Laundering regulation of Ghana, 2011.</li>
            <li>In case of any complaints regarding the service, you must lodge the said complaints within a period of thirty (30) calendar days from the date of detection of the anomaly</li>
            <li>You must use one of the following medium in lodging your complaints within the specified time period provided above - Call our Customer Support on 0599577963, visit our Head office at Dzorwulu or send us an email at <a href="malito: support@solisfinance.com">support@solisfinance.com</a>.</li>
            <li>In case of Fraud or scams, the customer must subsequently provide a police extract as part of his or her complaints to commence the necessary checks. Note that any loss incurred during this period will be a personal liability for which Solis shall not be responsible.</li>
            <li>You will be responsible for all fees and transactions effected up to the time of receipt by Solis of your report of the damage to, loss or theft of your SIM card.</li>
            <li>You must comply with any instructions that Solis may issue from time to time about the use of the Solis services.</li>
        </ol>
</p>

<p className="infoTexts"><b>13. Governing Law</b>
        <ol type='a'>
            <li>These terms and conditions and the account(s) shall be governed by the Laws of Ghana. You irrevocably submit to the non-exclusive jurisdiction of Ghana Courts.</li>
        </ol>
</p>

<p className="infoTexts"><b>14. Disclosure</b>
        <ol type='a'>
            <li>You hereby consent that we may disclose any information (including information relating to our business, accounts held with us) concerning you under the following circumstances: 
                <ol type='i'>
                    <li>Any of our offices or branches.</li>
                    <li>Any agent contractor or third-party service provider, or any professional advisor of ours.</li>
                    <li>Any guarantor or third-party security provider of your.</li>
                    <li>Any credit reference bureau, rating agency or collection agency.</li>
                    <li>Any regulatory, supervisory, governmental or quasi-governmental authority with jurisdiction over us.</li>
                    <li>Any actual or potential participant in, or assignee, novate or transferee of, any of the our rights and/or obligations in relation to you.</li>
                    <li>Any person to whom we are required or authorised by law or court order to make such disclosure</li>
                    <li>Any person who is under a duty of confidentially to us</li>
                    <li>Any overseas regulator or tax authority for the purpose of establishing any tax liability in compliance with an order, agreement with the overseas regulator or tax authorities.</li>
                </ol>
            </li>
        </ol>
</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermsAndConditionsPage