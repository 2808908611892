export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    GETSOLIS_ROUTE: '/get_solis',
    JOIN_US_ROUTE: '/join-us',
    PURPOSE_ROUTE: '/for-your-business',
    FAQS_ROUTE: '/FAQs',
    TERMS_AND_CONDITIONS_ROUTE: '/terms_and_conditions',
    PRIVACY_POLICY_ROUTE: '/privacy_policy',
    ABOUT_US_ROUTE: '/about-us',
    OUR_COMPANY_ROUTE: '/our-company',
    ASSET_FINANCING_ROUTE: '/asset-financing',
    RETAILERS_ROUTE: '/retailers',
    INCREASE_YOUR_CHANCES_ROUTE: '/increase-chances-of-winning',
    CONTACT_US_ROUTE: '/contact-us',
    TARGET_SAVINGS_ROUTE: '/target-savings',
    ACCOUNT_INFO_ROUTE: '/account-information'
}