import React, {useRef} from 'react'
import CustomNav from '../../components/Nav'
import LoginLandingHeader from '../../components/LoginLandingHeader'

const LoginPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CustomNav scrollToView_={scrollToView}/>
            <LoginLandingHeader />
            {/*<DateDisplay />*/}
        </div>
    )
}

export default LoginPage