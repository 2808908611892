import React, { useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNav from '../components/Nav'
import LogoCircle from '../components/LogoCircle'
import CustomNavAlt from '../components/NavAlt';

const AccountInfoPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        scrollToView()
    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <LogoCircle position="fluid" size="large" />
            <Container style={{ minHeight: '100vh', paddingTop: '100px' }}>
                <Row className="justify-content-center mt-4">
                    <Col xs={12} md={7}>
                        <h1 className="moreInfoHeader">Your Solis Account</h1>
                       <p>
                        <b>Closing Your Account</b>
                        <ol>
                            <li>Login to your profile on the mobile app</li>
                            <li>Select settings on the app</li>
                            <li>Select Account</li>
                            <li>Select Delete Profile</li>
                            <li>This will take you to a webpage where you would be requested to enter an OTP sent to the profile number.</li>
                            <li>Once OTP validation is successful, you would be requested to enter the profile phone number and proceed to finally close the account.</li>
                        </ol>
                       </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AccountInfoPage