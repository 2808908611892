import React, { useEffect, useRef } from 'react'
import DateDisplay from '../components/DateDisplay'
import CustomNav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoCircle from '../components/LogoCircle'

const IncreaseChances: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)
    
    useEffect(()=>{
        scrollToView()

    })

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: '145px' }}>
            <CustomNav scrollToView_={scrollToView}/>
            <div ref={topPageRef}></div>
            <LogoCircle position="fluid" size="large" />
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={7}>
                        <h1 className="moreInfoHeader">Increase Your Chances Of Winning</h1>
                        <p>
                            Make deposit in your Solis Account.
                        </p>
                        <p>
                            Earn daily intereest on Solis Account.
                        </p>
                        <p>
                            Daily interest earned is converted to points. (If you earn an interest of GHS 0.01, you will earn 1 point)
                        </p>
                        <p>
                            For every point earned daily, a ticket is generated and stored up for the weekly draw.
                        </p>
                        <p>
                            Therefore, the more the tickets earned during the week the higher your chances of winning a weekly cash prize.
                        </p>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default IncreaseChances