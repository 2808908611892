import React, {useRef} from 'react'
import DateDisplay from '../components/DateDisplay'
import CustomNav from '../components/Nav'
import GetSolisLandingHeader from '../components/GetSolisLandingHeader'
import JoinUsSteps from '../components/JoinUsSteps'
import CustomNavAlt from '../components/NavAlt'

const GetSolisPage: React.FC = () => {
    const topPageRef = useRef<null | HTMLDivElement>(null)

    const scrollToView = () => {topPageRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CustomNav scrollToView_={scrollToView}/> */}
            <CustomNavAlt scrollToView_={scrollToView}/>
            <GetSolisLandingHeader />
            <JoinUsSteps />
            {/*<DateDisplay />*/}
        </div>
    )
}

export default GetSolisPage