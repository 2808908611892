import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlackButton from './BlackButton'
import CustomNav from './Nav'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import LogoCircle from './LogoCircle'
import Loader from '../components/loader'
import WaitList from './waitList';

const LoginLandingHeader: React.FC = () => {
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [loading, setLoading] = useState(false);
    const [detailsSubmitted, setDetailsSubmitted] = useState(false)
    const [showError, setShowError] = useState(false)
    const [error_, setError_] = useState('')

    const formRef = useRef(null)

    const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
    const SHEET_ID = process.env.REACT_APP_SHEET_ID;
    const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
    const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;
    const SCRIPTURL = process.env.REACT_APP_SCRIPT

    const submitHandler = async (event: { preventDefault: () => void; }) => {
        event.preventDefault()

        setLoading(true)

        const params = {Name: firstName, Email: email, MobileNumber: mobileNumber}
        
        // axios.post('https://sheet.best/api/sheets/3a7996a0-b3a1-44b5-b87d-9b0c3a88b1fe', params)
        //     .then(response => {
        //         console.log("Response is ")
        //         console.log(response);
        //     })
        const regExp = /[a-zA-Z]/g;

        if(email=="" && mobileNumber==""){
            setError_("Please enter an email address or a mobile number")
            setLoading(false)
            setShowError(true)
        } else if (regExp.test(mobileNumber)) {
            setError_("Please enter a valid mobile number")
            setLoading(false)
            setShowError(true)
        } else {
            fetch(SCRIPTURL!, {method: 'POST', body: new FormData(formRef.current!)})
            .then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setFirstName('')
                setEmail('')
                setMobileNumber('')
                setLoading(false)
                setDetailsSubmitted(true)
                setShowError(false)
                setError_('')
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setShowError(false)
                setError_('')
            })
        }

    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(e.target.value)

		console.log(e.target.getAttribute('name'))

		switch(e.target.getAttribute('name')){
			case 'firstname':
				setFirstName(e.target.value)
				break;
			case 'email':
				setEmail(e.target.value)
				break;
            case "mobilenumber":
                setMobileNumber(e.target.value)
                break;
			default:
				return null;
		}

        email != '' ? setShowError(false) : mobileNumber != '' ? setShowError(false) : setShowError(true)
    }

    return (
        <div className="loginDiv">
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={6}>
                        <div className='loginSpace wait-list-div-1'><WaitList showText={true} /></div>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <img src="/images/Group.png" className="loginImage" />
                    </Col>
                </Row>
            </Container>
            <LogoCircle position="up-top" size="large" />
        </div>
    )
}

export default LoginLandingHeader