import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import HomePage from './pages/HomePage'
import LoginPage from './pages/auth/LoginPage'
import Container from 'react-bootstrap/Container';
import GetSolisPage from './pages/GetSolis'
import TermsAndConditionsPage from './pages/TermsAndConditions'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import FAQsPage from './pages/FAQs'
import AboutPage from './pages/AboutUs'
import IncreaseChances from './pages/IncreaseChances'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import Footer from './components/Footer'
import './styles/solis.sass'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/solis_mobile.css'
import './styles/solis_widescreen.css'
import './styles/solis_tablet.css'
import HomePageAlt from './pages/HomePageAlt';
import PurposePage from './pages/Purpose';
import AssetFinancing from './pages/AssetFinancing';
import Retailers from './pages/Retailers';
import ContactUsPage from './pages/ContactUs';
import TargetSavings from './pages/TargetSavings';
import AccountInfoPage from './pages/AccountInfo';

const RootComponent: React.FC = () => {
    return (
            <Router>
                <Routes>
                    {/* <Route path="*" element={<NotFoundPage />} /> */}
                    {/* <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} /> */}
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePageAlt />} />
                    <Route path={ROUTES.JOIN_US_ROUTE} element={<LoginPage />} />
                    <Route path={ROUTES.PURPOSE_ROUTE} element={<PurposePage />} />
                    <Route path={ROUTES.RETAILERS_ROUTE} element={<Retailers />} />
                    <Route path={ROUTES.ASSET_FINANCING_ROUTE} element={<AssetFinancing />} />
                    <Route path={ROUTES.GETSOLIS_ROUTE} element={<GetSolisPage />} />
                    <Route path={ROUTES.TERMS_AND_CONDITIONS_ROUTE} element={<TermsAndConditionsPage />} />
                    <Route path={ROUTES.PRIVACY_POLICY_ROUTE} element={<PrivacyPolicyPage />} />
                    <Route path={ROUTES.FAQS_ROUTE} element={<FAQsPage />} />
                    <Route path={ROUTES.OUR_COMPANY_ROUTE} element={<AboutPage />} />
                    <Route path={ROUTES.CONTACT_US_ROUTE} element={<ContactUsPage />} />
                    <Route path={ROUTES.ACCOUNT_INFO_ROUTE} element={<AccountInfoPage />} />
                    <Route path={ROUTES.INCREASE_YOUR_CHANCES_ROUTE} element={<IncreaseChances />} />
                    <Route path={ROUTES.TARGET_SAVINGS_ROUTE} element={<TargetSavings />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
                <Footer />
            </Router>
    )
}

export default RootComponent
