import React, { useState, CSSProperties } from 'react'
import FadeLoader from "react-spinners/FadeLoader";

type Load = {
    loading: boolean
}

const Loader: React.FC<Load> = ({loading}) => {
    const [color, setColor] = useState("#F06A66");

    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };

    return (<div className='loadScreen'>
        <div className="centerDiv">
            <FadeLoader color={color} loading={loading} />
        </div>
    </div>)
}

export default Loader